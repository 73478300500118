export const formatDateTime = (date) =>
  new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }).format(date);

export const formatMonth = (date) =>
  new Intl.DateTimeFormat('en-AU', {
    month: 'long',
  }).format(date);

export const parseISO8601ToYearDate = (iso8601String) => {
  const parsedDateMS = Date.parse(iso8601String);
  const parsedDate = new Date(parsedDateMS);

  const fDate = `${parsedDate.getFullYear()}-${parsedDate.getMonth() + 1}-${parsedDate.getDate()}`;

  return fDate;
};