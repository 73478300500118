import React, { useState, useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { List, Item} from '../List';
import { SearchForm } from '../SearchForm';
import { LastSearches } from '../LastSearches';
import { useStorageState } from '../../hooks/useStorageState';
import {
  storiesReducer,
  STORIES_FETCH_INIT,
  STORIES_FETCH_SUCCESS,
  STORIES_FETCH_FAILURE,
  REMOVE_STORY,
} from "../../hooks/storiesReducer";

const API_ENDPOINT = 'https://hn.algolia.com/api/v1/search?query=';

const API_BASE = 'https://hn.algolia.com/api/v1';
const API_SEARCH = '/search';
const PARAM_SEARCH = 'query=';
const PARAM_PAGE = 'page=';

const getUrl = (searchTerm, page) =>
`${API_BASE}${API_SEARCH}?${PARAM_SEARCH}${searchTerm}&${PARAM_PAGE}${page}`;

const extractSearchTerm = (url) =>
url.substring(url.lastIndexOf("?") + 1, url.lastIndexOf("&"))
.replace(PARAM_SEARCH, ''); 

const getLastSearchs = (urls) =>
_.uniq(urls.slice(-6).slice(0,-1).map(extractSearchTerm));


/* 
const extractSearchTerm = (url) => url.replace(API_ENDPOINT, '');

const getUrl = (searchTerm) => `${API_ENDPOINT}${searchTerm}`;
*/
 

/**
 * HackerStories Component starts
 */
const HackerStories = () => {
  // console.log('HackerStories renders');

  const [searchTerm, setSearchTerm] = useStorageState('search', 'React');

  const [urls, setUrls] = useState([
    getUrl(searchTerm, 0)
  ]);

  const [stories, dispatchStories] = useReducer(
    storiesReducer, 
    { data: [], page: 0, isLoading: false, isError: false}
  );
  
  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value);
  }

  const handleSearch = (searchTerm, page) => { 
    const url = getUrl(searchTerm, page);
    setUrls(urls.concat(url));
  }

  const handleLastSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    handleSearch(searchTerm, 0);
  };

  const lastSearches = getLastSearchs(urls);
  
  const handleSearchSubmit = (event) => {
    
    handleSearch(searchTerm, 0);

    /*
     * since the handler is used for the form event, it executes preventDefault() 
     * additionally on React’s synthetic event. This prevents the HTML form’s native 
     * behavior which would lead to a browser reload
    */
    event.preventDefault();
  };

  const handleMore = () => { 
    const lastUrl = urls[urls.length - 1];
    const searchTerm = extractSearchTerm(lastUrl);
    handleSearch(searchTerm, stories.page  + 1);
  };

  const handleFetchStories = useCallback( async () => { 

    dispatchStories({type: STORIES_FETCH_INIT});

    try {
      const lastUrl = urls[urls.length - 1]
      const result = await axios.get(lastUrl);

      dispatchStories({
        type: STORIES_FETCH_SUCCESS,
        payload: {
          list: result.data.hits,
          page: result.data.page,
        }
      });

    }catch {
      dispatchStories({type: STORIES_FETCH_FAILURE});
    }
  }, [urls]);


  useEffect(() => {
    handleFetchStories();
  }, [handleFetchStories])

  // use useCallback to memoize a function, 
  // which means that this function only gets RE-DEFINED if 
  // any of its dependencies in the dependency array change:
  const handleRemoveStory = useCallback( (item) => { 
    /* const newStories = stories.filter( (s) =>
      item.objectID !== s.objectID
    );

    //setStories(newStories);
    dispatchStories({
      type: 'SET_STORIES',
      payload: newStories
    }); */
    dispatchStories({
      type: REMOVE_STORY,
      payload: item,
    });

   }, []);
  
/* // client-side search, replaced by sever-side query
  const searchedStories = stories.data.filter( story =>
    story.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
 */

  

  return (
    <div className='container'>
      <h1 className='headline-primary'>My Hacker Stories</h1>
      
      <SearchForm 
        searchTerm={searchTerm} 
        onSearchInput={handleSearchInput}
        onSearchSubmit={handleSearchSubmit}  
      />

      <LastSearches 
        lastSearches={lastSearches}
        onLastSearch={handleLastSearch} />

      { stories.isError && <p>Something went wrong . . .</p>}

      <List list={stories.data} onRemoveItem={handleRemoveStory}/>

      { 
        stories.isLoading 
        ? ( <h3>Loading . . .</h3> ) 
        : (
            <button type='button' onClick={handleMore}>
              More
            </button>
         )
      }
      
      

      {/*
      
      <ComponentWithRefRead />
      */}

      
      {/* does something as 'ComponentWithRef' but no need to use
          useEffect and useRef Hooks anymore, because the callback ref
          gives you access to the DOM node on every render

        
      <ComponentWithRefCallBack />
      */}

      </div>
  );
}

function ComponentWithRefRead() {
  const [text, setText] = React.useState('Some text ...');

  function handleOnChange(event) {
    setText(event.target.value);
  }

  const ref = React.useRef();

  React.useEffect(() => {
    const { width, height } = ref.current.getBoundingClientRect();

    document.title = `Width:${width} / Height: ${height}`;
  }, []);

  return (
    <div>
      <input type="text" value={text} onChange={handleOnChange} />
      <div>
        <span ref={ref}>{text}</span>
      </div>
    </div>
  );
}

function ComponentWithRefCallBack() {
  const [text, setText] = React.useState('Some text ...');

  function handleOnChange(event) {
    setText(event.target.value);
  }

  const ref = useCallback((node) => {
    if (!node) return;

    const { width } = node.getBoundingClientRect();

    document.title = `Width 2:${width}`;
  },[text]);

  return (
    <div>
      <input type="text" value={text} onChange={handleOnChange} />
      <div>
        <span ref={ref}>{text}</span>
      </div>
    </div>
  );
}

export default HackerStories;

export { storiesReducer, Item, SearchForm };