
// import './Portfolio.style.css';

const Portfolio = () => { 

  return (
    <>
    {/* <!-- START NAV --> */}
    <div id="navbar" class="nav">
      <ul id="nav-list" class="nav-list">
        <li>
          <a href="#welcome-section">About</a>
        </li>
        <li>
          <a href="#projects">Work</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  {/* <!-- END NAV --> */}

  {/* <!-- STAET WELCOME SECTION --> */}
  <section id="welcome-section" class="welcome-section">
    <h1>Hello, I am Stephen Z</h1>
    <p>a web developer</p>
  </section>
  {/* <!-- END WELCOME SECTION --> */}


  {/* <!-- STAET PROJECTS SECTION --> */}
  <section id="projects" class="projects-section">
      <h2 class="projects-section-header">These are some of my projects</h2>

      <div class="projects-grid">
        <a href="https://codepen.io/freeCodeCamp/full/zNqgVx" target="_blank" class="project project-tile">
          <img class="project-image" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/tribute.jpg" alt="project" />
          <p class="project-title">
            <span class="code">&lt;</span>
            Tribute Page
            <span class="code">/&gt;</span>
          </p>
        </a>
        <a href="https://codepen.io/freeCodeCamp/full/qRZeGZ" target="_blank" class="project project-tile">
          <img class="project-image" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/random-quote-machine.png" alt="project" />
          <p class="project-title">
            <span class="code">&lt;</span>
            Random Quote Machine
            <span class="code">/&gt;</span>
          </p>
        </a>
        <a href="https://codepen.io/freeCodeCamp/full/wgGVVX" target="_blank" class="project project-tile">
          <img class="project-image" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/calc.png" alt="project" />
          <p class="project-title">
            <span class="code">&lt;</span>
            JavaScript Calculator
            <span class="code">/&gt;</span>
          </p>
        </a>
        <a href="https://codepen.io/freeCodeCamp/full/mVEJag" target="_blank" class="project project-tile">
          <img class="project-image" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/map.jpg" alt="project" />
          <p class="project-title">
            <span class="code">&lt;</span>
            Map Data Across the Globe
            <span class="code">/&gt;</span>
          </p>
        </a>
        <a href="https://codepen.io/freeCodeCamp/full/wGqEga" target="_blank" class="project project-tile">
          <img class="project-image" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/wiki.png" alt="project" />
          <p class="project-title">
            <span class="code">&lt;</span>
            Wikipedia Viewer
            <span class="code">/&gt;</span>
          </p>
        </a>
        <a href="https://codepen.io/freeCodeCamp/full/KzXQgy" target="_blank" class="project project-tile">
          <img class="project-image" src="https://cdn.freecodecamp.org/testable-projects-fcc/images/tic-tac-toe.png" alt="project" />
          <p class="project-title">
            <span class="code">&lt;</span>
            Tic Tac Toe Game
            <span class="code">/&gt;</span>
          </p>
        </a>
      </div>

      <a href="https://codepen.io/FreeCodeCamp/" class="btn btn-show-all" target="_blank">Show all<i class="fas fa-chevron-right"></i></a>
    </section>
  {/* <!-- END PROJECTS SECTION --> */}

  {/* <!-- STAET CONTACT SECTION --> */}
  <section id="contact" class="contact-section">
      <div class="contact-section-header">
        <h2>Let's work together...</h2>
        <p>How do you take your coffee?</p>
      </div>
      <div class="contact-links">
        <a href="https://facebook.com/freecodecamp" target="_blank" class="btn contact-details"><i class="fab fa-facebook-square"></i> Facebook</a>
        <a id="profile-link" href="https://github.com/freecodecamp" target="_blank" class="btn contact-details"><i class="fab fa-github"></i> GitHub</a>
        <a href="https://twitter.com/freecodecamp" target="_blank" class="btn contact-details"><i class="fab fa-twitter"></i> Twitter</a>
        <a href="mailto:schzhanggx@gmail.com" class="btn contact-details"><i class="fas fa-at"></i> Send a mail</a>
        <a href="tel:555-555-5555" class="btn contact-details"><i class="fas fa-mobile-alt"></i> Call me</a>
      </div>
    </section>
  {/* <!-- END CONTACT SECTION --> */}

  {/* <!-- START FOOTER --> */}
  <footer>
      <p>
        **This is just a fake portfolio. All the projects and contact details
        given are not real.
      </p>
      <p>
        © Created for
        <a href="https://www.freecodecamp.com/" target="_blank">freeCodeCamp <i class="fab fa-free-code-camp"></i></a>
      </p>
    </footer>
  {/* <!-- END FOOTER --> */}
    </>
  );
 };

 export default Portfolio;

