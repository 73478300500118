import React from 'react';
import { NavLink, Outlet } from "react-router-dom";
import './Layout.style.css';

const Layout = ({links}) => {

    const style = ({ isActive }) => {
      return {
        fontWeight: isActive ? "bold" : "normal",
      };
    };
  
    return (
      <>
        <nav className='nav'>
         {
            links.map( link => 
                <>
                    <NavLink to={link.path} style={style}>{link.name}</NavLink>  
                </>
            )
         }   
        </nav>
        <main className='main'>
          <Outlet />
        </main>
      </>
    );
  };

  export { Layout };