const STORIES_FETCH_INIT = 'STORIES_FETCH_INIT';
const STORIES_FETCH_SUCCESS = 'STORIES_FETCH_SUCCESS';
const STORIES_FETCH_FAILURE = 'STORIES_FETCH_FAILURE';
const REMOVE_STORY = 'REMOVE_STORY';

// custom useReducer Hook to manage multiple state transitions
const storiesReducer = (state, action) => {
  
    switch(action.type){
      case STORIES_FETCH_INIT:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case STORIES_FETCH_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: 
            action.payload.page === 0 
                ? action.payload.list
                : state.data.concat(action.payload.list),
          page: action.payload.page,
        };
      case STORIES_FETCH_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      case REMOVE_STORY:
        return {
          ...state,
          data: state.data.filter(story => action.payload.objectID !== story.objectID),
        }
      default:
        throw new Error();
    }
   
}

export {
    STORIES_FETCH_INIT,
    STORIES_FETCH_SUCCESS,
    STORIES_FETCH_FAILURE,
    REMOVE_STORY, 
    storiesReducer 
};