import * as React from 'react';
import './InputWithLabel.style.css';

const InputWithLabel = (props) => {

    const {id, type, value, onInputChange, isFocus, children} = props;
    
    // console.log('InputWithLabel renders');
    
    const inputRef = React.useRef();
  
    React.useEffect(() => {
      if(isFocus) {
        inputRef.current.focus();
      };  
    }, [isFocus])
    
  
    return (
      <div>
        <label htmlFor={id} className='label'>
          {children}
        </label>
        &nbsp;
        <input 
          id={id} 
          type={type}
          value={value}
          ref = {inputRef} 
          onChange={onInputChange}
          className='input'
        />
      </div>
    );
  };

export { InputWithLabel };
