import React, { useRef } from 'react';

 // create a Custom Hook to manage search term state in local-storage
 const useStorageState = (key, initialState) => {
  
    const isMounted = useRef(false);
    const [value, setValue] = React.useState( localStorage.getItem(key) ?? initialState);
  
    React.useEffect(() => {
      //  useRef's current to avoid side-effect function run at first-time render(mount)
      if (!isMounted.current) {
        isMounted.current = true;
      } else {
        console.log('A');
        localStorage.setItem(key, value);
      }
    }, [isMounted, key, value])
    
    return [value, setValue];
  }

  export { useStorageState };
