import * as React from 'react';
import { ReactComponent as Remove } from '../../remove.svg';
import { ReactComponent as ArrowUp } from '../../arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../arrow-down.svg';
import { parseISO8601ToYearDate as formatDate } from '../../utils/Format/date';
import _ from 'lodash';
import './List.style.css';

const SORTS = {
  NONE: (list) => list,
  TITLE: (list) => _.sortBy(list, "title"),
  AUTHOR: (list) => _.sortBy(list, "author"),
  COMMENT: (list) => _.sortBy(list, "num_comments").reverse(),
  CREATEDAT: (list) => _.sortBy(list, "created_at").reverse(),
  POINT: (list) => _.sortBy(list, "points").reverse(),
};


// use memo to make the equality check for the props
const List = React.memo(
    ({list, onRemoveItem}) =>  {

      const [sort, setSort] = React.useState({
        sortKey: 'NONE',
        isReverse: false,
      });

      const handleSort = (sortKey) => {
        const isReverse = sort.sortKey === sortKey && !sort.isReverse;
        setSort({sortKey, isReverse});
      };
      
      const sortFunc = SORTS[sort.sortKey];
      const sortedList = sort.isReverse
        ? sortFunc(list).reverse()
        : sortFunc(list);

      //console.log('B: List');
      return (
        <ol>
          {/* add headers to provide column on items */}
          <li className='item'>
            <span style={{ width: "40%" }}>
              <button type="button" onClick={() => handleSort("TITLE")}>
                Title { sort.isReverse ? <ArrowDown height='15px' width='15px'/> : <ArrowUp height='15px' width='15px'/> }
              </button>
            </span>
            <span style={{ width: "15%" }}>
              <button type="button" onClick={() => handleSort("AUTHOR")}>
                Author { sort.isReverse ? <ArrowDown height='15px' width='15px'/> : <ArrowUp height='15px' width='15px'/> }
              </button>
            </span>
            <span style={{ width: "15%" }}>
              <button type="button" onClick={() => handleSort("CREATEDAT")}>
                Created Date { sort.isReverse ? <ArrowDown height='15px' width='15px'/> : <ArrowUp height='15px' width='15px'/> }
              </button>
            </span>
            <span style={{ width: "10%" }}>
              <button type="button" onClick={() => handleSort("COMMENT")}>
                comments { sort.isReverse ? <ArrowDown height='15px' width='15px'/> : <ArrowUp height='15px' width='15px'/> }
              </button>
            </span>
            <span style={{ width: "10%" }}>
              <button type="button" onClick={() => handleSort("POINT")}>
                points { sort.isReverse ? <ArrowDown height='15px' width='15px'/> : <ArrowUp height='15px' width='15px'/> }
              </button>
            </span>
            <span style={{ width: "10%" }}></span>
          </li>

          {sortedList.map((item) => (
            <Item key={item.objectID} item={item} onRemoveItem={onRemoveItem} />
          ))}
        </ol>
      );
    }
  );
  
  const Item = ({item, onRemoveItem}) => {
  
    return(
      <li className='item'>
        {/* Inline style should be used sparingly */}
        <span style={{ width: '40%' }}><a href={item.url} >{item.title}</a></span>
        <span style={{ width: '15%' }}>{item.author}</span>
        <span style={{ width: '15%' }}>{formatDate(item.created_at)}</span>
        <span style={{ width: '10%', color: 'red' }}>{item.num_comments}</span>
        <span style={{ width: '10%', color: 'darkgreen' }}>{item.points}</span>
        <span style={{ width: '10%' }}>
          <button 
            type="button" 
            title="remove item"
            onClick={() => onRemoveItem(item) }
            className='button button_small button_remove'  
          >
            <Remove height='20px' width='20px'/>
          </button>
        </span>
      </li>
    );
  
  };

  export { List, Item };