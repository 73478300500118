import * as React  from 'react';
import { InputWithLabel } from '../InputWithLabel';
import { ReactComponent as Search } from '../../search.svg';
import './SearchForm.style.css';

const SearchForm = ({
    searchTerm, 
    onSearchInput, 
    onSearchSubmit,
  }) => {
    return (
      <form 
        onSubmit={onSearchSubmit}
        className='search-form'
      >
        <InputWithLabel
          id="search"
          type="text"
          value={searchTerm}
          isFocus
          onInputChange={onSearchInput}
        >
          <strong>Search Term:</strong>
        </InputWithLabel>
  
        <button
          data-testid="submit-button" 
          type='submit' 
          disabled={!searchTerm}
          className='button button_large button_search'
        >
        {/* Submit */}
          <Search height='36px' width='36px'/>
        </button>
      </form>
    );
  };

  export { SearchForm };