import { useState, useEffect } from 'react';

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
  } from '@table-library/react-table-library/table';
  import { CompactTable } from '@table-library/react-table-library/compact';
  import { useTheme } from '@table-library/react-table-library/theme';
  import { getTheme } from '@table-library/react-table-library/baseline';
  
  import config from '../../config/config.json';

  const ZHOU_AUDIO_PATH = '../zhou_audio/';

 const COLUMNS = [
  { label: 'Date', renderCell: (item) => item.date },
  { label: 'Name', renderCell: (item) => item.nameChinese },
  { label: 'English Title', renderCell: (item) => item.name },
];

const ZhouAudio = () => {
    const [nodes, setNodes] = useState([]);

    // const theme = useTheme(getTheme);
  
    const theme = useTheme({
      HeaderRow: `
        background-color: #eaf5fd;
      `,
      Row: `
        &:nth-of-type(odd) {
          background-color: #d2e9fb;
        }
  
        &:nth-of-type(even) {
          background-color: #eaf5fd;
        }
      `,
    });

    useEffect(() => {
        // load all audio list once on initial render
        const zhouAudio = config.zhouAudio;
        setNodes(zhouAudio);
      }, [])

    
    return (
        <>
            <CompactTable columns={COLUMNS} data={{nodes}} theme={theme} />
                <hr/>
                <ol>
                    {nodes.map( elt =>
                    <li key={elt.date}>
                        {elt.name} |  
                        {elt.nameChinese} |
                        <audio controls="controls">
                        <source src={ZHOU_AUDIO_PATH + elt.fileName} type="audio/mp4" />
                        </audio>
                    </li>
                    )}
                </ol>

                <hr/>

            <Table data={{nodes}} theme={theme}>
                {(tableList) => (
                <>
                    <Header>
                    <HeaderRow>
                        <HeaderCell>Name</HeaderCell>
                        <HeaderCell>Audio</HeaderCell>
                    </HeaderRow>
                    </Header>

                    <Body>
                    {tableList.map((item) => (
                        <Row key={item.id} item={item}>
                        <Cell>{item.name}</Cell>
                        <Cell>{item.nameChinese}</Cell>
                        </Row>
                    ))}
                    </Body>
                </>
                )}
            </Table>

        </>
        
    );
};

export default ZhouAudio;